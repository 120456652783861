// Angulars
import { NgModule } from '@angular/core';
import { BrowserModule, TransferState } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HttpClient } from '@angular/common/http';

// Libraries
import { NgxsModule } from '@ngxs/store';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { translateBrowserLoaderFactory } from '../shared/loaders/translate-browser.loader';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { MomentModule } from 'ngx-moment';
import { CountdownModule } from 'ngx-countdown';
import { DragDropModule } from '@angular/cdk/drag-drop';

// PrimeNG Libraries
import { MenubarModule } from 'primeng/menubar';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { SidebarModule } from 'primeng/sidebar';
import { AvatarModule } from 'primeng/avatar';
import { PanelModule } from 'primeng/panel';
import { TabViewModule } from 'primeng/tabview';
import { CardModule } from 'primeng/card';
import { InputTextModule } from 'primeng/inputtext';
import { InputNumberModule } from 'primeng/inputnumber';
import { PasswordModule } from 'primeng/password';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { EditorModule } from 'primeng/editor';
import { DropdownModule } from 'primeng/dropdown';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { DividerModule } from 'primeng/divider';
import { PaginatorModule } from 'primeng/paginator';
import { DialogModule } from 'primeng/dialog';
import { AutoFocusModule } from 'primeng/autofocus';
import { ToastModule } from 'primeng/toast';
import { DynamicDialogModule } from 'primeng/dynamicdialog';

// Pipes
import { NumeralPipe } from '../pipes/numeral.pipe';
import { PhonePipe } from '../pipes/phone.pipe';
import { ImageTransformationPipe } from '../pipes/image-transformation.pipe';

// Guards
import { AuthGuard } from '../guards/auth.guard';

// Configs
import { Config } from '../config/config';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json?v=' + Config.APP_VERSION);
}

@NgModule({
  declarations: [NumeralPipe, PhonePipe, ImageTransformationPipe],
  imports: [CommonModule],
  exports: [CommonModule, FormsModule, TranslateModule, FontAwesomeModule, NgxDropzoneModule, MomentModule, CountdownModule, DragDropModule, MenubarModule, TieredMenuModule, SidebarModule, AvatarModule, PanelModule, TabViewModule, CardModule, InputTextModule, InputNumberModule, PasswordModule, InputTextareaModule, EditorModule, DropdownModule, CalendarModule, CheckboxModule, DividerModule, PaginatorModule, DialogModule, AutoFocusModule, ToastModule, DynamicDialogModule, NumeralPipe, PhonePipe, ImageTransformationPipe],
  providers: [AuthGuard],
})
export class SharedModule {}
