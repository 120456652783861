<div class="p-b-10 text-center">
  <div class="m-b-10">
    <fa-icon class="f-90 txt-primary" [icon]="['fas', 'question-circle']"></fa-icon>
  </div>
  <div class="m-b-25 f-15" [innerHtml]="content"></div>
  <div>
    <button class="btn btn-primary m-r-2" (click)="onYesClick()">
      {{ "PHRASES.YES" | translate | uppercase }}
    </button>
    <button class="btn btn-danger" (click)="onCancelClick()">
      {{ "PHRASES.CANCEL" | translate | uppercase }}
    </button>
  </div>
</div>
