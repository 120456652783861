<p-sidebar [(visible)]="app.sidebar['left'].show" position="left" [modal]="false" [showCloseIcon]="false" [style]="{ width: app.sidebar['left'].width + 'px' }">
  <div class="sidebar-header sticky-top">
    <div class="d-flex justify-content-between align-menues-center">
      <div>
        <a [routerLink]="['/']">
          <div class="m-t-3">
            <img src="assets/images/app-name.png" height="36" />
          </div>
        </a>
      </div>
      <div>
        <div class="f-30 txt-white">
          <fa-icon [icon]="['fas', 'window-close']" class="c-pointer" (click)="app.sidebar['left'].show = false"></fa-icon>
        </div>
      </div>
    </div>
  </div>
  <app-sidebar-menu *ngIf="menues['main'] && app.isMenubarItemTranslated" [menues]="menues['main']" (onClick)="closeSidebar('left')"></app-sidebar-menu>
</p-sidebar>
