<div class="m-t-10">
  <!-- PARENT MENU -->
  <div *ngFor="let parentItem of menues; let parentItemIndex = index" class="parent-menu">
    <div *ngIf="parentItem.visible">
      <div *ngIf="!parentItem.separator" class="parent-menu">
        <div [routerLink]="parentItem.routerLink ? parentItem.routerLink : null" class="parent-item d-flex justify-content-between align-items-center" (click)="click(parentItem)">
          <div class="d-flex align-items-center">
            <!--
            <div class="w-22 m-r-5">
              <fa-icon [icon]="['fas', parentItem.icon.name]" [ngStyle]="{'font-size':parentItem.icon.size+'px'}"></fa-icon>
            </div>
            -->
            <div class="f-w-900">
              <b>{{ parentItem.label | uppercase }}</b>
            </div>
          </div>
          <div *ngIf="parentItem.items && parentItem.items.length > 0">
            <fa-icon *ngIf="!parentItem.isOpened" [icon]="['fas', 'chevron-right']" class="f-10"></fa-icon>
            <fa-icon *ngIf="parentItem.isOpened" [icon]="['fas', 'chevron-down']" class="f-10"></fa-icon>
          </div>
        </div>

        <!-- CHILD MENU -->
        <div *ngIf="parentItem.isOpened">
          <div *ngFor="let childItem of parentItem.items; let childItemIndex = index">
            <div *ngIf="childItem.visible">
              <div *ngIf="!childItem.separator" class="child-menu">
                <div>
                  <div [routerLink]="childItem.routerLink ? childItem.routerLink : null" class="d-flex justify-content-between align-items-center" [ngClass]="{ 'child-item': app.currentPage != childItem.linkedPage, 'child-item-selected': app.currentPage == childItem.linkedPage }" (click)="click(childItem)">
                    <div class="d-flex align-items-center">
                      <div class="w-22 m-r-5">
                        <fa-icon [icon]="['fas', childItem.icon.name]" [ngStyle]="{ 'font-size': childItem.icon.size + 'px' }"></fa-icon>
                      </div>
                      <div>{{ childItem.label }}</div>
                    </div>
                    <div *ngIf="childItem.items && childItem.items.length > 0">
                      <fa-icon *ngIf="!childItem.isOpened" [icon]="['fas', 'chevron-right']" class="f-10"></fa-icon>
                      <fa-icon *ngIf="childItem.isOpened" [icon]="['fas', 'chevron-down']" class="f-10"></fa-icon>
                    </div>
                  </div>
                </div>

                <!-- SUBCHILD MENU -->
                <div *ngIf="childItem.isOpened">
                  <div *ngFor="let subchildItem of childItem.items; let subchildItemIndex = index">
                    <div *ngIf="subchildItem.visible">
                      <div *ngIf="!subchildItem.separator" class="subchild-menu">
                        <div [routerLink]="subchildItem.routerLink ? subchildItem.routerLink : null" class="d-flex justify-content-between align-items-center" [ngClass]="{ 'subchild-item': app.currentPage != subchildItem.linkedPage, 'subchild-item-selected': app.currentPage == subchildItem.linkedPage }" (click)="click(subchildItem)">
                          <div class="d-flex align-items-center">
                            <div class="-m-t-5 m-r-5">
                              <fa-icon [icon]="['fas', 'dot-circle']" class="f-5"></fa-icon>
                            </div>
                            <div>{{ subchildItem.label }}</div>
                          </div>
                        </div>
                      </div>
                      <div *ngIf="subchildItem.separator" class="subchild-separator">
                        <p-divider></p-divider>
                      </div>

                      <!-- SPACE -->
                      <div *ngIf="subchildItemIndex == childItem.items.length - 1" class="h-15"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="childItem.separator" class="child-separator">
              <p-divider></p-divider>
            </div>

            <!-- SPACE -->
            <div *ngIf="childItemIndex == parentItem.items.length - 1" class="h-15"></div>
          </div>
        </div>
      </div>
      <div *ngIf="parentItem.separator" class="parent-separator">
        <p-divider></p-divider>
      </div>
    </div>
  </div>
</div>
