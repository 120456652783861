<div class="p-b-10 text-center">
  <div class="m-b-10">
    <fa-icon *ngIf="type == 'success'" class="f-90 txt-success" [icon]="['fas', 'check']"></fa-icon>
    <fa-icon *ngIf="type == 'error'" class="f-90 txt-danger" [icon]="['fas', 'times-circle']"></fa-icon>
    <fa-icon *ngIf="type == 'warning'" class="f-90 txt-warning" [icon]="['fas', 'exclamation-triangle']"></fa-icon>
    <fa-icon *ngIf="type == 'info'" class="f-90 txt-info" [icon]="['fas', 'info-circle']"></fa-icon>
  </div>
  <div class="m-b-25 f-15" [innerHtml]="content"></div>
  <div>
    <button class="btn btn-primary" (click)="onOkClick()">
      {{ "PHRASES.OK" | translate | uppercase }}
    </button>
  </div>
</div>
