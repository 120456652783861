// Angulars
import { Component, OnInit, Input } from '@angular/core';

// Helpers
import { UtilHelper } from '../../../helpers/util.helper';

// Services
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../../../services/auth.service';

// Components
import { AppComponent } from '../../../app.component';

@Component({
  selector: 'app-menubar',
  templateUrl: './menubar.component.html',
})
export class MenubarComponent implements OnInit {
  @Input() menues;

  constructor(
    protected app: AppComponent,
    protected translateService: TranslateService,
    protected util: UtilHelper,
    protected auth: AuthService,
  ) {}

  ngOnInit(): void {}
}
