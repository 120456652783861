// Environments
import { environment } from '../environments/environment';

// Angulars
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule, TransferState } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';

// Libraries
import { NgxsModule } from '@ngxs/store';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TransferHttpCacheModule } from '@nguniversal/common';
import { translateBrowserLoaderFactory } from './shared/loaders/translate-browser.loader';
import { NgxSpinnerModule } from 'ngx-spinner';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';

// Routings
import { AppRoutingModule } from './app-routing.module';

// Shared
import { SharedModule } from './shared/shared.module';
import { TranslatesService } from './shared/loaders/translates.service';

// Components
import { AppComponent } from './app.component';

// States
import { AuthState } from './modules/auth/auth.state';

// Interceptors
import { AppHttpInterceptor } from './http-interceptor';

// Layouts
import { BlankLayoutComponent } from './layouts/blank-layout.component';
import { AdminLayoutComponent } from './layouts/admin-layout.component';
import { MenubarComponent } from './layouts/components/menubar/menubar.component';
import { SidebarComponent } from './layouts/components/sidebar/sidebar.component';
import { SidebarMenuComponent } from './layouts/components/sidebar/sidebar-menu/sidebar-menu.component';

// Configs
import { Config } from './config/config';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json?v=' + Config.APP_VERSION);
}

export function setupTranslateFactory(translateService: TranslatesService): Function {
  return (): Promise<any> => translateService.initLanguage();
}

@NgModule({
  declarations: [AppComponent, SidebarMenuComponent, BlankLayoutComponent, AdminLayoutComponent, MenubarComponent, SidebarComponent, SidebarMenuComponent],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-app' }),
    BrowserAnimationsModule,
    TransferHttpCacheModule,
    AppRoutingModule,
    SharedModule,
    HttpClientModule,
    NgxsModule.forRoot([AuthState]),
    NgxsStoragePluginModule.forRoot({
      key: ['auth.user', 'auth.authorization', 'auth.permissions'],
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: translateBrowserLoaderFactory,
        deps: [HttpClient, TransferState],
      },
    }),
    NgxSpinnerModule.forRoot({ type: 'ball-8bits' }),
    FontAwesomeModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AppHttpInterceptor,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: setupTranslateFactory,
      deps: [TranslatesService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
