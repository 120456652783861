// Angulars
import { Injectable, Injector } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';

// Libraries
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';

// States
import { AuthState } from './modules/auth/auth.state';

// Configs
import { Config } from './config/config';

@Injectable()
export class AppHttpInterceptor implements HttpInterceptor {
  constructor(private store: Store) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let modifiedRequest = req;

    let authorization = this.store.selectSnapshot(AuthState.authorization);

    if (authorization) {
      modifiedRequest = req.clone({
        headers: req.headers
          .set('Authorization', 'Bearer ' + authorization.token)
          .set('X-' + Config.APPLICATION_NAME + '-App', Config.APP)
          .set('X-' + Config.APPLICATION_NAME + '-App-Version', Config.APP_VERSION),
      });

      return next.handle(modifiedRequest);
    } else {
      modifiedRequest = req.clone({
        headers: req.headers.set('X-' + Config.APPLICATION_NAME + '-App', Config.APP).set('X-' + Config.APPLICATION_NAME + '-App-Version', Config.APP_VERSION),
      });

      return next.handle(modifiedRequest);
    }
  }
}
