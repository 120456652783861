// Angulars
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { SharedModule } from '../../../shared.module';

// Libraries
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';

// PrimeNG Libraries
import { DialogService, DynamicDialogComponent, DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';

// Services
import { UtilHelper } from '../../../../helpers/util.helper';

// Components
import { AppComponent } from '../../../../app.component';

// Models
import { Model } from '../../../../models/model';

@UntilDestroy()
@Component({
  selector: 'app-alert-dialog',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './alert-dialog.component.html',
})
export class AlertDialogComponent implements OnInit {
  type: string = '';
  content: string = '';

  constructor(
    protected app: AppComponent,
    protected router: Router,
    protected route: ActivatedRoute,
    public dialogRef: DynamicDialogRef,
    public config: DynamicDialogConfig,
    library: FaIconLibrary,
    protected dialogService: DialogService,
    protected util: UtilHelper,
  ) {
    // Load library font awesome
    library.addIconPacks(fas, far);
  }

  ngOnInit(): void {
    this.type = this.config.data.type;
    this.content = this.config.data.content;
  }

  onOkClick() {
    this.dialogRef.close();
  }
}
