// Angulars
import { Component, OnInit } from '@angular/core';

// Components
import { AppComponent } from '../app.component';

@Component({
  templateUrl: './blank-layout.component.html',
})
export class BlankLayoutComponent implements OnInit {
  constructor(protected app: AppComponent) {}

  ngOnInit(): void {}
}
