// Angulars
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

// Libraries
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';

// Components
import { AppComponent } from '../../../../app.component';

@Component({
  selector: 'app-sidebar-menu',
  templateUrl: './sidebar-menu.component.html',
  styleUrls: ['./sidebar-menu.component.scss'],
})
export class SidebarMenuComponent implements OnInit {
  @Input() menues;
  @Output() onClick = new EventEmitter<any>();

  constructor(
    protected app: AppComponent,
    protected router: Router,
    library: FaIconLibrary,
  ) {
    library.addIconPacks(fas, far);
  }

  ngOnInit(): void {
    this.initOpenedMenu(this.menues);
  }

  /**
   * Fungsi untuk inisialisasi daftar menu yang terbuka berdasarkan halaman yang dibuka
   * @param menues
   */
  initOpenedMenu(menues: any[]) {
    for (let menu of menues) {
      if (this.app.currentPage == menu.linkedPage) {
        menu.isOpened = true;

        // Jika menu mempunyai induk, maka buka induknya juga
        if (menu.parent) {
          this.openParentMenu(menu.parent);
        }
      }

      if (menu.items) {
        this.initOpenedMenu(menu.items);
      }
    }
  }

  /**
   * Fungsi untuk membuka menu induk
   * @param menu
   */
  openParentMenu(menu) {
    menu.isOpened = true;

    // Jika menu mempunyai induk, maka buka induknya juga
    if (menu.parent) {
      this.openParentMenu(menu.parent);
    }
  }

  /**
   * Fungsi yang ditrigger pada saat menu diklik
   * @param menu
   */
  click(menu) {
    if (menu.items && menu.items.length > 0) {
      menu.isOpened = !menu.isOpened;
    } else if (menu.routerLink && this.app.currentPage != menu.linkedPage) {
      this.onClick.emit(menu);
    }
  }
}
