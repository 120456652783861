export class RouteCollection {
  public static readonly ROOT = '';

  public static readonly HOME = '/home';

  public static readonly LOGIN = '/login';
  public static readonly LOGOUT = '/logout';

  public static readonly USER = '/user';

  public static readonly POST = '/crawling/post';
  public static readonly AUTHOR = '/crawling/author';
  public static readonly CRAWLING_TASK = '/crawling/task';

  public static readonly SELLER = '/crawling/seller';
  public static readonly SELLER_TYPE = '/crawling/seller-type';
  public static readonly SELLER_STATUS = '/crawling/seller-status';
  public static readonly PRODUCT = '/crawling/product';
  public static readonly CATEGORY = '/crawling/category';

  public static readonly PROVINCE = '/configuration/province';
  public static readonly CITY = '/configuration/city';
  public static readonly SUBDISTRICT = '/configuration/subdistrict';
  public static readonly PLATFORM = '/configuration/platform';
  public static readonly REGION = '/configuration/region';
  public static readonly CRAWLING_TYPE = '/configuration/crawling-type';
  public static readonly NOTIFICATION_FORMAT = '/configuration/notification-format';
  public static readonly APPLICATION_VERSION = '/configuration/application-version';
  public static readonly APPLICATION_VERSION_TYPE = '/configuration/application-version-type';
  public static readonly ADMINISTRATOR = '/configuration/administrator';
  public static readonly ROLE = '/configuration/role';
  public static readonly PERMISSION = '/configuration/permission';

  public static readonly SCHEDULER = '/scheduler';
}
