// Angulars
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

// Libraries
import { Observable } from 'rxjs';
import * as auth from 'firebase/auth';
import { AngularFireAuth } from '@angular/fire/compat/auth';

// Services
import { LocalStorageService } from './local-storage.service';
import { AppService } from './app.service';

// Configs
import { ApiCollection } from '../config/api.collection';
import { RouteCollection } from '../config/route.collection';

@Injectable({
  providedIn: 'root',
})
export class AuthService extends AppService {
  protected url = ApiCollection.AUTH_LOGIN;
  protected loginWithFirebaseAuthTokenUrl = ApiCollection.AUTH_LOGIN_WITH_FIREBASE_AUTH_TOKEN;
  protected infoUrl = ApiCollection.AUTH_INFO;

  public user: any;
  public permissions: boolean[] = [];

  constructor(
    protected http: HttpClient,
    protected router: Router,
    protected localStorage: LocalStorageService,
    public afAuth: AngularFireAuth,
  ) {
    super(http, router, localStorage);
  }

  setUser(user) {
    this.user = user;
  }

  setPermission(permissions) {
    this.permissions = [];
    for (let i = 0; i < permissions.length; i++) {
      this.permissions[permissions[i].slug] = true;
    }
  }

  removeUser() {
    this.user = undefined;
    this.permissions = [];
  }

  login(model): Observable<any> {
    this.setHeader();

    return this.http.post(this.url, JSON.stringify({ user: model.username, password: model.password }), { headers: this.headers['raw'] });
  }

  info(): Observable<any> {
    this.setHeader();

    return this.http.get(this.infoUrl, { headers: this.headers['raw'] });
  }

  connectToFirebaseAuth(provider: any) {
    return new Promise(resolve => {
      this.setHeader();

      this.afAuth
        .signInWithPopup(provider)
        .then((result: any) => {
          resolve(result.user._delegate.accessToken);
        })
        .catch(error => {
          resolve(null);
        });
    });
  }

  loginWithFirebaseAuthToken(token): Observable<any> {
    this.setHeader();

    return this.http.post(this.loginWithFirebaseAuthTokenUrl, JSON.stringify({ token: token }), { headers: this.headers['raw'] });
  }
}
