export class Login {
  static readonly type = '[Auth] Login';

  constructor(public payload: any) {}
}

export class LoginWithFirebaseAuthToken {
  static readonly type = '[Auth] Login with Firebase Auth Token';

  constructor(public payload: any) {}
}

export class Logout {
  static readonly type = '[Auth] Logout';
}

export class Info {
  static readonly type = '[Auth] Info';
}
