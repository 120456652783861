// Angulars
import { Injectable } from '@angular/core';

// Libraries
import { State, Selector, StateContext, Action } from '@ngxs/store';
import { tap } from 'rxjs/operators';

// Services
import { AuthService } from '../../services/auth.service';

// Actions
import { Login, LoginWithFirebaseAuthToken, Logout, Info } from './auth.action';

// Models
import { Auth } from '../../models/auth';

@State<Auth>({
  name: 'auth',
  defaults: {
    user: null,
    authorization: null,
    permissions: [],
  },
})
@Injectable()
export class AuthState {
  @Selector()
  static user(state: Auth): any {
    return state.user;
  }

  @Selector()
  static authorization(state: Auth): any {
    return state.authorization;
  }

  @Selector()
  static permissions(state: Auth): any[] {
    return state.permissions;
  }

  constructor(private authService: AuthService) {}

  @Action(Login)
  login({ patchState }: StateContext<Auth>, { payload }: Login) {
    return this.authService.login(payload).pipe(
      tap(result => {
        patchState({
          user: result.data,
          authorization: result.authorization,
          permissions: result.data.role_user.role.permissions,
        });
      }),
    );
  }

  @Action(LoginWithFirebaseAuthToken)
  loginWithFirebaseAuthToken({ patchState }: StateContext<Auth>, { payload }: Login) {
    return this.authService.loginWithFirebaseAuthToken(payload).pipe(
      tap(result => {
        patchState({
          user: result.data,
          authorization: result.authorization,
          permissions: result.data.role_user.role.permissions,
        });
      }),
    );
  }

  @Action(Logout)
  logout({ setState }: StateContext<Auth>) {
    setState({
      user: null,
      authorization: null,
      permissions: [],
    });
  }

  @Action(Info)
  info({ patchState }: StateContext<Auth>) {
    return this.authService.info().pipe(
      tap(result => {
        patchState({
          user: result.data,
          permissions: result.data.role[0].permissions,
        });
      }),
    );
  }
}
