// Angulars
import { Component, OnInit } from '@angular/core';

// Libraries
import { Store } from '@ngxs/store';
import { timer } from 'rxjs';
import * as S from 'string';

// Helpers
import { UtilHelper } from '../helpers/util.helper';

// Services
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../services/auth.service';

// Components
import { AppComponent } from '../app.component';

// States
import { AuthState } from '../modules/auth/auth.state';

// Configs
import { RouteCollection } from '../config/route.collection';

@Component({
  templateUrl: './admin-layout.component.html',
})
export class AdminLayoutComponent implements OnInit {
  // Menu
  menues: any[] = [];

  // Permissions
  permissions: any[] = [];

  constructor(
    protected app: AppComponent,
    private store: Store,
    protected translateService: TranslateService,
    protected util: UtilHelper,
    protected auth: AuthService,
  ) {}

  ngOnInit(): void {
    // Inisialisasi akses
    this.loadPermission();

    // Inisialisasi main menu
    this.loadMenu('main');
    this.translateMenu(this.menues['main']);
    this.checkMenuPermission(this.menues['main']);
    this.hideUnallowedMenu(this.menues['main']);

    // Inisialisasi profile menu
    this.loadMenu('profile');
    this.translateMenu(this.menues['profile']);
  }

  /**
   * Fungsi untuk inisialisasi daftar menu
   * @param type
   */
  loadMenu(type) {
    // Menubar
    if (type == 'main') {
      this.menues[type] = [
        {
          id: 'user',
          items: [{ id: 'user', icon: { name: 'user', size: 13 }, permission: 'view-user', routerLink: [RouteCollection.USER], linkedPage: 'user' }],
        },
        {
          id: 'crawling',
          items: [
            /*
                        { id: 'post', icon: { name: 'book', size: 13 }, permission: 'view-post', routerLink: [RouteCollection.POST], linkedPage: 'post' },
                        { id: 'author', icon: { name: 'user', size: 13 }, permission: 'view-author', routerLink: [RouteCollection.AUTHOR], linkedPage: 'author' },
                        { separator: true },
                        */
            { id: 'seller', icon: { name: 'user', size: 13 }, permission: 'view-seller', routerLink: [RouteCollection.SELLER], linkedPage: 'seller' },
            { id: 'seller-type', icon: { name: 'user', size: 13 }, permission: 'view-seller-type', routerLink: [RouteCollection.SELLER_TYPE], linkedPage: 'seller-type' },
            { id: 'seller-status', icon: { name: 'user', size: 13 }, permission: 'view-seller-status', routerLink: [RouteCollection.SELLER_STATUS], linkedPage: 'seller-status' },
            { separator: true },
            { id: 'product', icon: { name: 'book', size: 13 }, permission: 'view-product', routerLink: [RouteCollection.PRODUCT], linkedPage: 'product' },
            { separator: true },
            { id: 'category', icon: { name: 'globe', size: 13 }, permission: 'view-category', routerLink: [RouteCollection.CATEGORY], linkedPage: 'category' },
            { separator: true },
            { id: 'crawling_task', icon: { name: 'globe', size: 13 }, permission: 'view-crawling-task', routerLink: [RouteCollection.CRAWLING_TASK], linkedPage: 'crawling_task' },
          ],
        },
        {
          id: 'configuration',
          items: [
            {
              id: 'location',
              icon: { name: 'map', size: 13 },
              items: [{ id: 'province', permission: 'view-province', routerLink: [RouteCollection.PROVINCE], linkedPage: 'province' }, { separator: true }, { id: 'city', permission: 'view-city', routerLink: [RouteCollection.CITY], linkedPage: 'city' }, { id: 'subdistrict', permission: 'view-subdistrict', routerLink: [RouteCollection.SUBDISTRICT], linkedPage: 'subdistrict' }],
            },
            { separator: true },
            {
              id: 'crawling',
              icon: { name: 'globe', size: 13 },
              items: [{ id: 'platform', permission: 'view-platform', routerLink: [RouteCollection.PLATFORM], linkedPage: 'platform' }, { separator: true }, { id: 'region', permission: 'view-region', routerLink: [RouteCollection.REGION], linkedPage: 'region' }, { separator: true }, { id: 'crawling-type', permission: 'view-crawling-type', routerLink: [RouteCollection.CRAWLING_TYPE], linkedPage: 'crawling-type' }],
            },
            { separator: true },
            { id: 'notification-format', icon: { name: 'paste', size: 15 }, permission: 'view-notification-format', routerLink: [RouteCollection.NOTIFICATION_FORMAT], linkedPage: 'notification-format' },
            {
              id: 'application',
              icon: { name: 'code-merge', size: 15 },
              items: [{ id: 'application-version', permission: 'view-application-version', routerLink: [RouteCollection.APPLICATION_VERSION], linkedPage: 'application-version' }, { separator: true }, { id: 'application-version-type', permission: 'view-application-version-type', routerLink: [RouteCollection.APPLICATION_VERSION_TYPE], linkedPage: 'application-version-type' }],
            },
            {
              id: 'administrator',
              icon: { name: 'user-cog', size: 13 },
              items: [{ id: 'administrator', permission: 'view-administrator', routerLink: [RouteCollection.ADMINISTRATOR], linkedPage: 'administrator' }, { separator: true }, { id: 'role', permission: 'view-role', routerLink: [RouteCollection.ROLE], linkedPage: 'role' }, { id: 'permission', permission: 'view-permission', routerLink: [RouteCollection.PERMISSION], linkedPage: 'permission' }],
            },
          ],
        },
        {
          id: 'developer',
          items: [{ id: 'scheduler', icon: { name: 'clock', size: 15 }, permission: 'view-scheduler', routerLink: [RouteCollection.SCHEDULER], linkedPage: 'scheduler' }],
        },
      ];
    }

    // Profile
    else if (type == 'profile') {
      this.menues[type] = [
        { id: 'change_profile' },
        { separator: true },
        {
          id: 'logout',
          command: event => {
            this.app.logout();
          },
        },
      ];
    }
  }

  /**
   * Fungsi untuk menerjemahkan daftar menu
   * @param menues
   */
  translateMenu(menues: any[]) {
    // Terjemahkan menu
    for (let menu of menues) {
      // Abaikan jika separator
      if (menu.separator) {
        continue;
      }

      this.translateService.get('PHRASES.' + S(menu.id.toUpperCase()).replaceAll('-', '_').s).subscribe(result => {
        menu.label = result;
      });

      if (menu.items) {
        this.translateMenu(menu.items);
      }
    }

    // Tampilkan menu
    timer(1).subscribe(result => {
      this.app.isMenubarItemTranslated = true;
    });
  }

  /**
   * Fungsi untuk mengambil daftar akses user
   */
  loadPermission() {
    let permissions = this.store.selectSnapshot(AuthState.permissions);
    for (let i = 0; i < permissions.length; i++) {
      this.permissions[permissions[i].slug] = true;
    }
  }

  /**
   * Fungsi untuk mengecek daftar menu berdasarkan akses user
   * @param menues
   * @param parent
   */
  checkMenuPermission(menues: any[], parent?) {
    // Proses item berdasarkan akses user
    for (let menu of menues) {
      // Inisialiasi parent item
      if (parent) {
        menu.parent = parent;
      }

      // Abaikan jika separator
      if (menu.separator) {
        continue;
      }

      if (menu.permission) {
        if (this.permissions[menu.permission]) {
          if (parent) {
            parent.allowed = true;
          }

          menu.allowed = true;
        } else {
          menu.allowed = false;
        }
      }

      if (menu.items) {
        this.checkMenuPermission(menu.items, menu);
      }
    }
  }

  /**
   * Fungsi untuk menyembunyikan menu yang tidak bisa diakses user
   * @param menues
   */
  hideUnallowedMenu(menues: any[]) {
    let separator: any = undefined;

    // Proses item berdasarkan akses user
    for (let menu of menues) {
      // Jika separator, cek juga apakah separator diperlukan
      if (menu.separator) {
        separator = menu;
      }

      if (menu.allowed) {
        menu.visible = true;

        // Tampilkan separator di atasnya
        if (separator) {
          separator.visible = true;
        }
      } else {
        menu.visible = false;
      }

      if (menu.allowed && menu.items) {
        this.hideUnallowedMenu(menu.items);
      }
    }
  }
}
