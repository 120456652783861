export class Config {
  public static readonly APPLICATION_NAME = 'Tikhub';

  public static readonly APP = 'admin-web';
  public static readonly APP_VERSION = '0.0.28';

  public static readonly DEFAULT_LANG = 'id';

  public static readonly SEARCH_DEBOUNCE_TIME_DURATION = 500;
  public static readonly SEARCH_MINIMUM_LENGTH = 3;

  public static readonly PAGINATION_ROWS_PER_PAGE = 50;
  public static readonly PAGINATION_ROWS_PER_PAGE_OPTIONS = [50, 100, 150, 200, 250];
}
