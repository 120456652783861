import { environment } from '../../environments/environment';

export class ApiCollection {
  public static readonly AUTH_LOGIN = environment.apiUrl + 'v1/auth/login';
  public static readonly AUTH_LOGIN_WITH_FIREBASE_AUTH_TOKEN = environment.apiUrl + 'v1/auth/login_with_firebase_auth_token';
  public static readonly AUTH_LOGOUT = environment.apiUrl + 'v1/auth/logout';
  public static readonly AUTH_INFO = environment.apiUrl + 'v1/auth/info';

  public static readonly USERS = environment.apiUrl + 'v1/users';

  public static readonly POSTS = environment.apiUrl + 'v1/posts';
  public static readonly AUTHORS = environment.apiUrl + 'v1/authors';
  public static readonly AUTHOR_RUN = environment.apiUrl + 'v1/authors/[:author_id]/run';
  public static readonly CRAWLING_TASKS = environment.apiUrl + 'v1/crawling_tasks';
  public static readonly CRAWLING_TASK_RUN = environment.apiUrl + 'v1/crawling_tasks/[:crawling_task_id]/run';
  public static readonly CRAWLING = environment.apiUrl + 'v1/crawling/[:crawling_type]';

  public static readonly SELLERS = environment.apiUrl + 'v1/sellers';
  public static readonly SELLER_TYPES = environment.apiUrl + 'v1/seller_types';
  public static readonly SELLER_STATUSES = environment.apiUrl + 'v1/seller_statuses';
  public static readonly PRODUCTS = environment.apiUrl + 'v1/products';
  public static readonly CATEGORIES = environment.apiUrl + 'v1/categories';

  public static readonly CONFIGURATIONS = environment.apiUrl + 'v1/configurations';
  public static readonly PROVINCES = environment.apiUrl + 'v1/provinces';
  public static readonly PROVINCE_SYNC = environment.apiUrl + 'v1/provinces/sync';
  public static readonly CITIES = environment.apiUrl + 'v1/cities';
  public static readonly SUBDISTRICTS = environment.apiUrl + 'v1/subdistricts';
  public static readonly PLATFORMS = environment.apiUrl + 'v1/platforms';
  public static readonly REGIONS = environment.apiUrl + 'v1/regions';
  public static readonly CRAWLING_TYPES = environment.apiUrl + 'v1/crawling_types';
  public static readonly NOTIFICATION_FORMATS = environment.apiUrl + 'v1/notification_formats';
  public static readonly APPLICATION_VERSIONS = environment.apiUrl + 'v1/applications/versions';
  public static readonly APPLICATION_VERSION_TYPES = environment.apiUrl + 'v1/applications/version_types';
  public static readonly ADMINISTRATORS = environment.apiUrl + 'v1/administrators';
  public static readonly ROLES = environment.apiUrl + 'v1/roles';
  public static readonly PERMISSIONS = environment.apiUrl + 'v1/permissions';

  public static readonly SCHEDULERS = environment.apiUrl + 'v1/schedulers';
  public static readonly SCHEDULER_RUN = environment.apiUrl + 'v1/schedulers/[:scheduler_id]/run';

  public static readonly TOKENS = environment.apiUrl + 'v1/tokens';
}
