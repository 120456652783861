export const environment = {
  production: true,
  apiUrl: 'https://api.tikhub.pasarmerah.com/',
  firebase: {
    apiKey: 'AIzaSyCjSV6EPv7o6mG8F7Uj1WRWbQ5Gyf5TLKE',
    authDomain: 'tikhub-bca6d.firebaseapp.com',
    projectId: 'tikhub-bca6d',
    storageBucket: 'tikhub-bca6d.appspot.com',
    messagingSenderId: '12427460915',
    appId: '1:12427460915:web:e8b4883c7d308f9d13be46',
    measurementId: 'G-J7TXHWMH3R',
  },
};
