<div class="d-block sticky-top">
  <div class="p-menubar">
    <div class="container-fluid">
      <div class="col-12">
        <div class="p-15-10 d-flex justify-content-between align-items-center">
          <div class="d-flex align-items-center">
            <div class="f-20 m-r-15">
              <fa-icon [icon]="['fas', 'bars-staggered']" class="c-pointer" (click)="app.sidebar['left'].show = true"></fa-icon>
              <p-tieredMenu #profile_menu appendTo="body" [model]="menues['profile']" [popup]="true"></p-tieredMenu>
            </div>
            <div class="d-flex">
              <a [routerLink]="['/']" class="m-r-10">
                <div>
                  <img src="assets/images/app-name.png" height="20" />
                </div>
              </a>
              <div class="d-none d-md-block">
                <span class="badge badge-danger f-8">{{ app.config.APP_VERSION }}</span>
              </div>
            </div>
          </div>
          <div class="d-flex align-items-center">
            <div class="f-15 m-r-10">
              <span class="c-pointer" (click)="profile_menu.toggle($event)">{{ auth.user.name }}</span>
            </div>
            <div class="f-20">
              <fa-icon [icon]="['fas', 'grip-vertical']" class="c-pointer" (click)="profile_menu.toggle($event)"></fa-icon>
              <p-tieredMenu #profile_menu appendTo="body" [model]="menues['profile']" [popup]="true"></p-tieredMenu>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!--
<p-sidebar [(visible)]="app.sidebar['left'].show" position="left" [showCloseIcon]="false" [style]="{width:'250px'}">
  <div class="sidebar-header sticky-top">
    <div class="d-flex justify-content-between align-items-center">
      <div>
        <a [routerLink]="['/']">
          <div>
            <img src="assets/images/app-name.png" height="20">
          </div>
          <div class="-m-t-2">
            <span class="badge badge-danger f-8">{{app.config.APP_VERSION}}</span>
          </div>
        </a>
      </div>
      <div>
        <div class="f-30">
          <fa-icon [icon]="['fas', 'xmark']" class="c-pointer" (click)="app.sidebar['left'].show = false"></fa-icon>
        </div>
      </div>
    </div>
  </div>
  <app-sidebar-menu *ngIf="(menues['main']) && (app.isMenubarItemTranslated)" [items]="menues['main']" (onClick)="app.sidebar['left'].show = false"></app-sidebar-menu>
</p-sidebar>
-->

<!-- ALTERNATIVE MENUBAR -->
<!--
<p-menubar *ngIf="(menues['main']) && (app.isMenubarItemTranslated)" [model]="menues['main']" class="d-none d-lg-block sticky-top">
  <ng-template pTemplate="start">
    <div class="container">
      <div class="col-12 text-center">
        <div class="d-flex align-items-center">
          <div class="m-r-20">
            <a [routerLink]="['/']">
              <div>
                <img src="assets/images/logo.png" height="24">
              </div>
              <div class="-m-t-5 -m-b-5">
                <span class="f-8 txt-white">{{app.config.APP_VERSION}}</span>
              </div>
            </a>
          </div>
          <div class="f-20 opacity-05 -m-r-5">|</div>
        </div>
      </div>
    </div>
  </ng-template>
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="d-flex align-items-center">
          <div class="f-15 m-r-20">
            <p-avatar icon="pi pi-user" styleClass="txt-dark m-r-5" shape="circle"></p-avatar>
            {{auth.user.name}}
          </div>
          <div class="f-20 opacity-05 m-r-20">|</div>
          <div class="f-20">
            <fa-icon [icon]="['fas', 'grip-vertical']" class="c-pointer" (click)="profile_menu.toggle($event)"></fa-icon>
            <p-tieredMenu #profile_menu appendTo="body" [model]="menues['profile']" [popup]="true"></p-tieredMenu>
          </div>
        </div>
      </div>
    </div>
  </div>
</p-menubar>
-->
