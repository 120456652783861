// Angulars
import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

// Libraries
import { Store } from '@ngxs/store';

// Services
import { LocalStorageService } from '../services/local-storage.service';

// States
import { AuthState } from '../modules/auth/auth.state';

// Configs
import { RouteCollection } from '../config/route.collection';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private store: Store,
    private router: Router,
    protected localStorage: LocalStorageService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let authorization = this.store.selectSnapshot(AuthState.authorization);

    if (authorization) {
      return true;
    }

    this.router.navigate([RouteCollection.LOGIN], { queryParams: { returnUrl: state.url } });

    return false;
  }
}
