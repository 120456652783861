// Angulars
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

// Libraries
import { TranslateService } from '@ngx-translate/core';
import * as S from 'string';

// Configs
import { RouteCollection } from '../config/route.collection';

@Injectable({
  providedIn: 'root',
})
export class UtilHelper {
  constructor(
    protected router: Router,
    public translateService: TranslateService,
  ) {}

  /**
   * Fungsi untuk menerjemahkan frase
   * @param phrase
   * @param callback
   * @returns
   */
  translate(phrase: string, instant: boolean = true): any {
    if (instant) {
      return this.translateService.instant(phrase);
    } else {
      return this.translateService.get(phrase);
    }
  }

  /**
   * Fungsi untuk menguraikan object
   * @param obj
   * @returns
   */
  parseObject(obj: any): object {
    return JSON.parse(JSON.stringify(obj));
  }

  /**
   * Fungsi untuk menduplikat object
   * @param src
   * @param dest
   */
  cloneObject(src: any, dest: any, replaceDuplicated: boolean = true) {
    for (let i = 0; i < Object.keys(src).length; i++) {
      if (!dest[Object.keys(src)[i]] || replaceDuplicated) {
        dest[Object.keys(src)[i]] = src[Object.keys(src)[i]];
      }
    }
  }

  /**
   * Fungsi untuk mencari objek berdasarkan nilai tertentu
   * @param obj
   * @param find
   * @param value
   * @returns
   */
  indexOfObject(obj: any, find: any, value: any): number {
    return obj
      .map(result => {
        return result[find];
      })
      .indexOf(value);
  }

  /**
   * Fungsi untuk kembali ke halaman sebelumnya
   */
  historyBack() {
    if (history.length > 1) {
      history.back();
    } else {
      this.router.navigate([RouteCollection.ROOT]);
    }
  }

  /**
   * Fungsi untuk membuat string menjadi huruf kecil semua
   * @param str
   * @returns
   */
  lowercase(str: string): string {
    return str.toLowerCase();
  }

  /**
   * Fungsi untuk membuat string menjadi huruf besar semua
   * @param str
   * @returns
   */
  uppercase(str: string): string {
    return str.toUpperCase();
  }

  /**
   * Fungsi untuk mengganti \n menjadi <br>
   * @param str
   * @param is_xhtml
   * @returns
   */
  nl2br(str: string, is_xhtml: boolean = false): string {
    const breakTag = is_xhtml || typeof is_xhtml == 'undefined' ? '<br ' + '/>' : '<br>';

    return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
  }

  /**
   * Fungsi untuk mencari dan mengganti string
   * @param str
   * @param find
   * @param replace
   * @returns
   */
  replaceAll(str: string, find: string, replace: string) {
    return S(str).replaceAll(find, replace).s;
  }
}
