// Angulars
import { Routes } from '@angular/router';

// Guards
import { AuthGuard } from './guards/auth.guard';

// Layouts
import { BlankLayoutComponent } from './layouts/blank-layout.component';
import { AdminLayoutComponent } from './layouts/admin-layout.component';

export const APP_ROUTES: Routes = [
  {
    path: '',
    component: AdminLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', redirectTo: 'crawling/seller', pathMatch: 'full' },

      /* EXAMPLES */
      { path: 'examples', loadChildren: () => import('./components/examples/example.module').then(m => m.ExampleModule) },

      { path: 'user', loadChildren: () => import('./components/datas/user/user.module').then(m => m.UserModule) },

      { path: 'crawling', loadChildren: () => import('./components/crawlings/crawling.module').then(m => m.CrawlingModule) },

      { path: 'configuration', loadChildren: () => import('./components/configurations/configuration.module').then(m => m.ConfigurationModule) },

      { path: 'scheduler', loadChildren: () => import('./components/developers/scheduler/scheduler.module').then(m => m.SchedulerModule) },
    ],
  },
  {
    path: '',
    component: BlankLayoutComponent,
    children: [{ path: 'login', loadChildren: () => import('./components/authentications/login.module').then(m => m.LoginModule) }],
  },
  {
    path: '',
    component: AdminLayoutComponent,
    children: [{ path: '**', loadChildren: () => import('./page-not-found.module').then(m => m.PageNotFoundModule) }],
  },
];
