// Angulars
import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

// Libraries
import { Store } from '@ngxs/store';
import { Observable, of } from 'rxjs';
import * as querystring from 'querystring';

// Services
import { LocalStorageService } from './local-storage.service';

// Configs
import { Config } from '../config/config';
import { ApiCollection } from '../config/api.collection';
import { RouteCollection } from '../config/route.collection';

@Injectable({
  providedIn: 'root',
})
export class AppService {
  protected url;

  protected headers: HttpHeaders[] = [];

  constructor(
    protected http: HttpClient,
    protected router: Router,
    protected localStorage: LocalStorageService,
  ) {}

  /**
   * Fungsi untuk menyetel header
   */
  setHeader() {
    if (this.localStorage.getItem('authorization')) {
      this.headers['raw'] = new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json').set('Accept-Language', this.localStorage.getItem('lang'));

      this.headers['form-data'] = new HttpHeaders().set('Accept', 'application/json').set('Accept-Language', this.localStorage.getItem('lang'));
    } else {
      this.headers['raw'] = new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json').set('Accept-Language', this.localStorage.getItem('lang'));

      this.headers['form-data'] = new HttpHeaders().set('Accept', 'application/json').set('Accept-Language', this.localStorage.getItem('lang'));
    }
  }

  /**
   * Fungsi untuk mendapatkan daftar data
   * @param params
   * @returns
   */
  get(params: any): Observable<any> {
    this.setHeader();

    let url = this.url;
    if (params) {
      url += '?' + querystring.stringify(params);
    }

    return this.http.get(url, { headers: this.headers['raw'] });
  }

  /**
   * Fungsi untuk mendapatan data dengan id tertentu
   * @param id
   * @returns
   */
  show(id: string): Observable<any> {
    this.setHeader();

    let url = this.url + '/' + id;

    return this.http.get(url, { headers: this.headers['raw'] });
  }

  /**
   * Fungsi untuk menyimpan data (create / update)
   * @param type
   * @param model
   * @param bodyType
   * @returns
   */
  save(action = 'create', model: any, bodyType: string = 'raw'): Observable<any> {
    if (action == 'create') {
      return this.create(model, bodyType);
    } else {
      return this.update(model, bodyType);
    }
  }

  /**
   * Fungsi untuk membuat data baru
   * @param model
   * @param bodyType
   * @returns
   */
  create(model: any, bodyType: string = 'raw'): Observable<any> {
    this.setHeader();

    let body: any;
    let headers = {};
    switch (bodyType) {
      case 'raw':
        body = JSON.stringify(model);
        headers = this.headers['raw'];
        break;
      case 'form-data':
        body = new FormData();
        for (let i = 0; i < Object.keys(model).length; i++) {
          body.append(Object.keys(model)[i], model[Object.keys(model)[i]]);
        }
        headers = this.headers['form-data'];
        break;
    }
    return this.http.post(this.url, body, { headers: headers });
  }

  /**
   * Fungsi untuk mengubah data
   * @param model
   * @param bodyType
   * @returns
   */
  update(model: any, bodyType: string = 'raw'): Observable<any> {
    this.setHeader();

    let url = this.url + '/' + model.id;

    let body: any;
    let headers = {};
    switch (bodyType) {
      case 'raw':
        body = JSON.stringify(model);
        headers = this.headers['raw'];
        break;
      case 'form-data':
        body = new FormData();
        for (let i = 0; i < Object.keys(model).length; i++) {
          body.append(Object.keys(model)[i], model[Object.keys(model)[i]]);
        }
        headers = this.headers['form-data'];
        break;
    }

    return this.http.patch(url, body, { headers: headers });
  }

  /**
   * Fungsi untuk menghapus data
   * @param id
   * @returns
   */
  delete(id: string): Observable<any> {
    this.setHeader();

    let url = this.url + '/' + id;

    return this.http.delete(url, { headers: this.headers['raw'] });
  }

  /**
   * Fungsi untuk observable kosongan (buat keperluan digabung dengan combine, merge, concat)
   * @returns
   */
  empty(): Observable<any> {
    return of({});
  }
}
