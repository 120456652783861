// Angulars
import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { DOCUMENT, isPlatformBrowser, isPlatformServer } from '@angular/common';

// Libraries
import { REQUEST } from '@nguniversal/express-engine/tokens';
import { TranslateService as NGXTranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
// import { MetaService } from '@ngx-meta/core';
// import { AppStorage } from '../for-storage/universal.inject';

// Models
import { ILang } from './translates.interface';

const LANG_LIST: ILang[] = [
  { code: 'en', name: 'English', culture: 'en-US' },
  { code: 'id', name: 'Indonesia', culture: 'id-ID' },
];
const LANG_DEFAULT: ILang = LANG_LIST[0];
const STORAGE_LANG_NAME: string = 'langCode';

@Injectable({
  providedIn: 'root',
})
export class TranslatesService {
  constructor(
    @Inject(PLATFORM_ID) private _platformId: Object,
    @Inject(DOCUMENT) private _document: any,
    @Inject(REQUEST) private _request: Request,
    @Inject(NGXTranslateService) private _translate: NGXTranslateService,
    // @Inject(MetaService) private _meta: MetaService,
    // @Inject(AppStorage) private _appStorage: Storage
  ) {}

  initLanguage(): Promise<any> {
    return new Promise((resolve: Function) => {
      this._translate.addLangs(LANG_LIST.map((lang: ILang) => lang.code));
      this._translate.setDefaultLang(LANG_DEFAULT.code);
      const language: ILang = this._getLanguage();
      this._setLanguage(language);
      resolve();
    });
  }

  _getLanguage(): ILang {
    let language: any = this._getFindLang('id');
    if (language) {
      return language;
    }
    if (isPlatformBrowser(this._platformId)) {
      language = this._getFindLang(this._translate.getBrowserLang());
    }
    if (isPlatformServer(this._platformId)) {
      try {
        const reqLangList: string[] = this._request.headers['accept-language'].split(';')[0].split(',');
        language = LANG_LIST.find((lang: ILang) => reqLangList.indexOf(lang.code) != -1 || reqLangList.indexOf(lang.culture) != -1);
      } catch (err) {
        language = LANG_DEFAULT;
      }
    }
    language = language || LANG_DEFAULT;
    //this._appStorage.setItem(STORAGE_LANG_NAME, language.code);
    return language;
  }

  _getFindLang(code: any): any {
    return code ? LANG_LIST.find((lang: ILang) => lang.code == code) : null;
  }

  _setLanguage(lang: ILang): void {
    this._translate.use(lang.code).subscribe(() => {
      // this._meta.setTag('og:locale', lang.culture);
      this._document.documentElement.lang = lang.code;
    });
  }

  changeLang(code: string): void {
    const lang: ILang = this._getFindLang(code);
    if (!lang || lang.code == this._translate.currentLang) {
      return;
    }
    //this._appStorage.setItem(STORAGE_LANG_NAME, lang.code);
    window.location.reload();
  }

  getLangList(): Observable<ILang[]> {
    return of(LANG_LIST);
  }

  getCurrentLang(): string {
    return this._translate.currentLang;
  }
}
